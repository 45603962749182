import Vue, { KeepAlive } from 'vue'
import VueRouter from 'vue-router'

// 白名单（这里是根据路由名字判断的）
const whiteList = ['LoginView', 'register', 'forgetPwd', 'loginIndex']

Vue.use(VueRouter)

// 全局导航守卫函数 --- // 认证状态 & token
const globalGuard = async (to, from, next) => {
  const token = localStorage.getItem('_access_token') || false
  const reviewStatus = JSON.parse(localStorage.getItem('userInfo'))?.reviewStatus == 1 || false
  // const reviewStatus = ''
  if (token) {
    // 用户已登录
    if (reviewStatus) {
      // 审核通过
      if (to.name === 'MerchantCA') {
        // 如果是审核页面，则重定向到首页
        // next('/LogisticsManagement')
        next('/Staging')
      } else {
        // 其他页面，放行
        next()
      }
    } else if ([...whiteList, 'MerchantCA'].includes(to.name)) {
      // 未审核通过但在白名单内的页面，放行
      next()
    } else {
      // 未审核通过但非白名单内的页面，放行
      next('/MerchantCA')
    }
  } else if (whiteList.includes(to.name)) {
    // 未登录但在白名单内的页面，放行
    next()
  } else {
    // 未登录且不在白名单内的页面，重定向到登录页
    next('/loginView')
  }
}

export const routes = [
  {
    // 404页面
    path: '*',
    name: '404',
    component: () => import('@/views/ErrorPage/index.vue')
  },
  {
    // 默认登录页
    path: '',
    redirect: 'loginView'
  },
  {
    path: '/MerchantCA',
    name: 'MerchantCA',
    meta: {
      title: '店铺认证'
    },
    component: () => import(/* webpackChunkName: "MerchantCA" */ '@/views/StoreCenter/MerchantCA')
  },
  {
    path: '/global',
    name: 'GlobalView',
    redirect: '/globalIndex',
    component: () => import(/* webpackChunkName: "GlobalView" */ '@/views/GlobalView'),
    children: [
      {
        path: '/Staging',
        name: 'Staging',
        activeKey: '/Staging',
        meta: {
          id: 'Staging',
          title: '工作台',
          icon: 'staging'
        },
        component: () => import(/* webpackChunkName: "Staging" */ '@/views/Staging'),
      },
      // 数据可视化
      {
        path: '/globalIndex',
        name: 'Index',
        meta: {
          id: 'Index',
          title: '数据可视化'
        },
        redirect: '/globalIndex/dataProfile',
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Index'),
        children: [
          {
            path: 'dataProfile',
            name: 'DataProfile',
            activeKey: '/globalIndex',
            meta: {
              title: '数据概况'
            },
            component: () => import(/* webpackChunkName: "DataProfile" */ '@/views/Index/DataProfile')
          },
          {
            path: 'productInsight',
            name: 'ProductInsight',
            activeKey: '/globalIndex',
            meta: {
              title: '商品洞察'
            },
            component: () => import(/* webpackChunkName: "ProductInsight" */ '@/views/Index/ProductInsight')
          },
          // {
          //   path: 'ShopInsight',
          //   name: 'ShopInsight',
          //   activeKey: '/ShopInsight',
          //   meta: {
          //     title: '店铺洞察'
          //   },
          //   component: () => import(/* webpackChunkName: "ShopInsight" */ '@/views/Index/ShopInsight')
          // }
        ]
      },
      {
        path: '/FinancialCenter',
        name: 'FinancialCenter',
        activeKey: '/FinancialCenter',
        meta: {
          id: 'FinancialCenter',
          title: '财务中心',
          icon: 'caiwuzhongxin'
        },
        component: () => import(/* webpackChunkName: "FinancialCenter" */ '@/views/FinancialCenter'),
        children: [
          {
            path: 'TransactionDetails',
            name: 'TransactionDetails',
            activeKey: '/FinancialCenter',
            meta: {
              title: '收支明细'
            },
            component: () => import(/* webpackChunkName: "TransactionDetails" */ '@/views/FinancialCenter/TransactionDetails')
          },
          {
            path: 'AccountManagement',
            name: 'AccountManagement',
            activeKey: '/FinancialCenter',
            meta: {
              title: '账户管理'
            },
            component: () => import(/* webpackChunkName: "AccountManagement" */ '@/views/FinancialCenter/AccountManagement')
          },
          {
            path: 'bankAccount',
            name: 'bankAccount',
            activeKey: '/FinancialCenter',
            meta: {
              title: '账户管理',
              redirectPath: '/FinancialCenter/AccountManagement',
            },
            component: () => import(/* webpackChunkName: "bankAccount" */ '@/views/FinancialCenter/bankAccount')
          },
          {
            path: 'FreightMonthlyOrder',
            name: 'FreightMonthlyOrder',
            activeKey: '/FinancialCenter',
            meta: {
              title: '运费月结单'
            },
            component: () => import(/* webpackChunkName: "FreightMonthlyOrder" */ '@/views/FinancialCenter/FreightMonthlyOrder')
          },
          {
            path: 'MonthlyOrderDetails',
            name: 'MonthlyOrderDetails',
            meta: {
              title: '月结单详情',
              redirectPath: '/FinancialCenter/FreightMonthlyOrder',
              customPath: [
                { name: 'FinancialCenter', title: '财务中心', icon: 'caiwuzhongxin' },
                { name: 'FreightMonthlyOrder', title: '运费月结单', to: 'FreightMonthlyOrder' },
                { name: 'MonthlyOrderDetails', title: '月结单详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "FreightMonthlyOrder" */ '@/views/FinancialCenter/FreightMonthlyOrder/modules/MonthlyOrderDetails')
          },
          {
            path: 'ReconciliationReport',
            name: 'ReconciliationReport',
            activeKey: '/FinancialCenter',
            meta: {
              title: '对账报表'
            },
            component: () => import(/* webpackChunkName: "ReconciliationReport" */ '@/views/FinancialCenter/ReconciliationReport')
          },
        ]
      },
      {
        path: '/ProductCenter',
        name: 'ProductCenter',
        meta: {
          id: 'ProductCenter',
          title: '商品中心',
          icon: 'shangpinzhongxin'
        },
        component: () => import(/* webpackChunkName: "ProductCenter" */ '@/views/ProductCenter'),
        children: [
          {
            path: 'ProductSYS',
            name: 'ProductSYS',
            meta: {
              title: '商品管理',
              keepAlive: true,
            },
            component: () => import(/* webpackChunkName: "ProductSYS" */ '@/views/ProductCenter/ProductSYS')
          },
          {
            path: 'editProduct',
            name: 'editProduct',
            meta: {
              title: '编辑商品',
              redirectPath: '/ProductCenter/ProductSYS'
            },
            component: () => import(/* webpackChunkName: "editProduct" */ '@/views/ProductCenter/ProductSYS/modules/commodity.vue')
          },
          {
            path: 'addProduct',
            name: 'addProduct',
            meta: {
              title: '新建商品',
              redirectPath: '/ProductCenter/ProductSYS'
            },
            component: () => import(/* webpackChunkName: "editProduct" */ '@/views/ProductCenter/ProductSYS/modules/commodity.vue')
          }
        ]
      },
      {
        path: '/OrderCenter',
        name: 'OrderCenter',
        activeKey: '/OrderCenter',
        meta: {
          id: 'OrderCenter',
          title: '订单中心',
          icon: 'dingdanzhongxin'
        },
        component: () => import(/* webpackChunkName: "OrderCenter" */ '@/views/OrderCenter'),
        children: [
          {
            path: 'OrderManagement',
            name: 'OrderManagement',
            activeKey: '/OrderCenter',
            meta: {
              title: '订单管理'
            },
            component: () => import(/* webpackChunkName: "OrderManagement" */ '@/views/OrderCenter/OrderManagement')
          },
          {
            path: 'afterSaleSYS',
            name: 'afterSaleSYS',
            activeKey: '/OrderCenter',
            meta: {
              title: '售后管理'
            },
            component: () => import(/* webpackChunkName: "afterSaleSYS" */ '@/views/OrderCenter/afterSaleSYS')
          },
          {
            path: 'afterSaleDetail',
            name: 'afterSaleDetail',
            activeKey: '/OrderCenter',
            meta: {
              title: '售后详情',
              redirectPath: '/OrderCenter/afterSaleSYS',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'afterSaleSYS', title: '售后管理', to: 'afterSaleSYS' },
                { name: 'afterSaleDetail', title: '售后详情' }
              ]
            },
            component: () => import(/* webpackChunkName: "afterSaleDetail" */ '@/views/OrderCenter/afterSaleSYS/afterSaleDetail')
          },
          {
            path: 'chatRecord',
            name: 'chatRecord',
            activeKey: '/OrderCenter',
            meta: {
              title: '协商记录',
              redirectPath: '/OrderCenter/afterSaleSYS',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'afterSaleSYS', title: '售后管理', to: 'afterSaleSYS' },
                { name: 'chatRecord', title: '协商记录' }
              ]
            },
            component: () => import(/* webpackChunkName: "chatRecord" */ '@/views/OrderCenter/afterSaleSYS/chatRecord')
          },
          {
            path: 'SelfPickupVerification',
            name: 'SelfPickupVerification',
            activeKey: 'SelfPickupVerification',
            redirectPath: '/OrderCenter/SelfPickupVerification',
            meta: {
              title: '自提核销'
            },
            component: () => import(/* webpackChunkName: "SelfPickupVerification" */ '@/views/OrderCenter/SelfPickupVerification')
          },
          {
            path: 'OrderDetails',
            name: 'OrderDetails',
            meta: {
              title: '订单详情',
              redirectPath: '/OrderCenter/OrderManagement',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'OrderManagement', title: '订单管理', to: 'OrderManagement' },
                { name: 'OrderDetails', title: '订单详情' }
              ]
            },
            component: () =>
              import(/* webpackChunkName: "OrderDetails" */ '@/views/OrderCenter/OrderManagement/modules/OrderDetails.vue')
          },
          {
            path: 'SelfPickupVerification',
            name: 'SelfPickupVerification',
            activeKey: 'SelfPickupVerification',
            redirectPath: '/OrderCenter/SelfPickupVerification',
            meta: {
              title: '自提核销'
            },
            component: () => import(/* webpackChunkName: "SelfPickupVerification" */ '@/views/OrderCenter/SelfPickupVerification')
          },
          {
            path: 'OrderDispatch',
            name: 'OrderDispatch',
            activeKey: '/OrderCenter',
            redirectPath: '/OrderCenter/OrderDispatch',
            meta: {
              title: '订单发货'
            },
            component: () => import(/* webpackChunkName: "OrderDispatch" */ '@/views/OrderCenter/OrderDispatch')
          },
          {
            path: 'OrderDispatchDetails',
            name: 'OrderDispatchDetails',
            meta: {
              title: '订单详情',
              redirectPath: '/OrderCenter/OrderDispatch',
              customPath: [
                { name: 'OrderCenter', title: '订单中心', icon: 'dingdanzhongxin' },
                { name: 'OrderDispatch', title: '订单发货', to: 'OrderDispatch' },
                { name: 'OrderDispatchDetails', title: '发货详情' }
              ]
            },
            component: () =>
              import(/* webpackChunkName: "OrderDetails" */ '@/views/OrderCenter/OrderDispatch/modules/OrderDispatchDetails.vue')
          },
        ]
      },
      {
        path: '/CustomerCenter',
        name: 'CustomerCenter',
        meta: {
          id: 'CustomerCenter',
          title: '客户中心',
          icon: 'kehuzhongxin'
        },
        component: () => import(/* webpackChunkName: "CustomerCenter" */ '@/views/CustomerCenter'),
        children: [
          {
            path: 'CustomerManagement',
            name: 'CustomerManagement',
            activeKey: '/CustomerCenter',
            meta: {
              title: '客户管理'
            },
            component: () => import(/* webpackChunkName: "CustomerManagement" */ '@/views/CustomerCenter/CustomerManagement')
          }
        ]
      },
      {
        path: '/StoreCenter',
        name: 'StoreCenter',
        activeKey: '/StoreCenter',
        meta: {
          id: 'StoreCenter',
          title: '店铺中心',
          icon: 'dianpuzhongxin'
        },
        redirect: '/StoreCenter/StroeManagement',
        component: () => import(/* webpackChunkName: "StoreCenter" */ '@/views/StoreCenter'),
        children: [
          {
            path: 'LogisticsManagement',
            name: 'LogisticsManagement',
            activeKey: '/LogisticsCenter',
            meta: {
              title: '通用设置'
            },
            component: () => import(/* webpackChunkName: "LogisticsManagement" */ '@/views/StoreCenter/LogisticsManagement')
          },
          {
            path: 'groupSYS',
            name: 'groupSYS',
            activeKey: '/LogisticsCenter',
            meta: {
              title: '店铺分类'
            },
            component: () => import(/* webpackChunkName: "GroupSYS" */ '@/views/StoreCenter/GroupSYS')
          },
          {
            path: 'StroeManagement',
            name: 'StroeManagement',
            activeKey: '/StroeManagement',
            redirect: '/StoreCenter/StroeManagement/StoreInformation',
            meta: {
              title: '店铺管理'
            },
            component: () => import(/* webpackChunkName: "StroeManagement" */ '@/views/StoreCenter/StroeManagement'),
            children: [
              {
                path: 'StoreInformation',
                name: 'StoreInformation',
                activeKey: '/StoreCenter/StroeManagement',
                meta: {
                  title: '店铺信息'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "StoreInformation" */ '@/views/StoreCenter/StroeManagement/modules/storeInformation.vue'
                  )
              },
              {
                path: 'CloseStore',
                name: 'CloseStore',
                activeKey: '/StoreCenter/StroeManagement',
                meta: {
                  title: '店铺打烊',
                  redirectPath: '/StoreCenter/StroeManagement/StoreInformation',
                  customPath: [
                    { name: 'StoreCenter', title: '店铺中心', icon: 'shangpinzhongxin' },
                    { name: 'StroeManagement', title: '店铺管理', to: 'StroeManagement' },
                    { name: 'CloseStore', title: '店铺打烊' }
                  ]
                },
                component: () =>
                  import(/* webpackChunkName: "CloseStore" */ '@/views/StoreCenter/StroeManagement/modules/closeStore.vue')
              },
              {
                path: 'StoreDetail',
                name: 'StoreDetail',
                meta: {
                  title: '修改店铺信息',
                  redirectPath: '/StoreCenter/StroeManagement/StoreInformation',
                  customPath: [
                    { name: 'StoreCenter', title: '店铺中心', icon: 'shangpinzhongxin' },
                    { name: 'StroeManagement', title: '店铺管理', to: 'StroeManagement' },
                    { name: 'StoreDetail', title: '修改店铺信息' }
                  ]
                },
                component: () =>
                  import(/* webpackChunkName: "StoreDetail" */ '@/views/StoreCenter/StroeManagement/modules/storeDetail.vue')
              },
              {
                path: 'Closed',
                name: 'Closed',
                meta: {
                  title: '关店详情',
                  redirectPath: '/StoreCenter/StroeManagement/StoreInformation',
                  customPath: [
                    { name: 'StoreCenter', title: '店铺中心', icon: 'shangpinzhongxin' },
                    { name: 'StroeManagement', title: '店铺管理', to: 'StroeManagement' },
                    { name: 'Closed', title: '关店详情' }
                  ]
                },
                component: () => import(/* webpackChunkName: "Closed" */ '@/views/StoreCenter/StroeManagement/modules/closed.vue')
              }
            ]
          },
          {
            path: 'CompanyInformation',
            name: 'CompanyInformation',
            component: () => import(/* webpackChunkName: "CompanyInformation" */ '@/views/StoreCenter/CompanyInformation'),
            meta: {
              title: '企业信息'
            },
            redirect: '/StoreCenter/CompanyInformation/Information',
            children: [
              {
                path: 'Information',
                name: 'Information',
                activeKey: '/StoreCenter/CompanyInformation',
                component: () =>
                  import(/* webpackChunkName: "Information" */ '@/views/StoreCenter/CompanyInformation/modules/index.vue')
              },
              {
                path: 'UpdateInformation',
                name: 'UpdateInformation',
                meta: {
                  title: '编辑企业信息',
                  redirectPath: '/StoreCenter/CompanyInformation/Information',
                  customPath: [
                    { name: 'StoreCenter', title: '店铺中心', icon: 'shangpinzhongxin' },
                    { name: 'CompanyInformation', title: '企业信息', to: 'CompanyInformation' },
                    { name: 'UpdateInformation', title: '编辑企业信息' }
                  ]
                },
                component: () =>
                  import(/* webpackChunkName: "UpdateInformation" */ '@/views/StoreCenter/CompanyInformation/modules/update.vue')
              }
            ]
          },
          {
            path: 'storeFitment',
            name: 'storeFitment',
            component: () => import(/* webpackChunkName: "storeFitment" */ '@/views/StoreCenter/storeFitment'),
            meta: {
              title: '店铺装修'
            }
          },
          {
            path: 'ShopQualification',
            name: 'ShopQualification',
            component: () => import(/* webpackChunkName: "ShopQualification" */ '@/views/StoreCenter/ShopQualification'),
            activeKey: '/ShopQualification',
            redirect: '/StoreCenter/ShopQualification/ShopQualificationList',
            meta: {
              title: '店铺资质'
            },
            children: [
              {
                path: 'ShopQualificationList',
                name: 'ShopQualificationList',
                activeKey: '/StoreCenter/ShopQualification',
                meta: {
                  redirectPath: '/StoreCenter/ShopQualification'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "ShopQualificationList" */ '@/views/StoreCenter/ShopQualification/module/index.vue'
                  )
              },
              {
                path: 'SubmitQualification',
                name: 'SubmitQualification',
                activeKey: '/StoreCenter/ShopQualification',
                meta: {
                  title: '提交资质',
                  redirectPath: '/StoreCenter/ShopQualification'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SubmitQualification" */ '@/views/StoreCenter/ShopQualification/module/submit.vue'
                  )
              },
              {
                path: 'EditQualification',
                name: 'EditQualification',
                activeKey: '/StoreCenter/ShopQualification',
                meta: {
                  title: '提交资质',
                  redirectPath: '/StoreCenter/ShopQualification'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "EditQualification" */ '@/views/StoreCenter/ShopQualification/module/edit'
                  )
              },
              {
                path: 'QualificationDetail',
                name: 'QualificationDetail',
                activeKey: '/StoreCenter/ShopQualification',
                meta: {
                  title: '资质详情',
                  redirectPath: '/StoreCenter/ShopQualification'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "QualificationDetail" */ '@/views/StoreCenter/ShopQualification/module/detail.vue'
                  )
              },
            ]
          },
          {
            path: 'BrandAuthorization',
            name: 'BrandAuthorization',
            component: () => import(/* webpackChunkName: "BrandAuthorization" */ '@/views/StoreCenter/BrandAuthorization'),
            activeKey: '/BrandAuthorization',
            redirect: '/StoreCenter/BrandAuthorization/BrandAuthorizationList',
            meta: {
              title: '品牌授权'
            },
            children: [
              {
                path: 'BrandAuthorizationList',
                name: 'BrandAuthorizationList',
                activeKey: '/StoreCenter/BrandAuthorization',
                meta: {
                  redirectPath: '/StoreCenter/BrandAuthorization'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "BrandAuthorizationList" */ '@/views/StoreCenter/BrandAuthorization/module/index.vue'
                  )
              },
              {
                path: 'SubmitAuthorization',
                name: 'SubmitAuthorization',
                activeKey: '/StoreCenter/BrandAuthorization',
                meta: {
                  title: '提交授权',
                  redirectPath: '/StoreCenter/BrandAuthorization'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SubmitAuthorization" */ '@/views/StoreCenter/BrandAuthorization/module/submit.vue'
                  )
              },
              {
                path: 'EditAuthorization',
                name: 'EditAuthorization',
                activeKey: '/StoreCenter/BrandAuthorization',
                meta: {
                  title: '提交授权',
                  redirectPath: '/StoreCenter/BrandAuthorization'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "EditAuthorization" */ '@/views/StoreCenter/BrandAuthorization/module/edit.vue'
                  )
              },
              {
                path: 'AuthorizationDetail',
                name: 'AuthorizationDetail',
                activeKey: '/StoreCenter/BrandAuthorization',
                meta: {
                  title: '授权详情',
                  redirectPath: '/StoreCenter/BrandAuthorization'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "AuthorizationDetail" */ '@/views/StoreCenter/BrandAuthorization/module/detail.vue'
                  )
              },
            ]
          },
        ]
      },
      {
        path: '/SystemSettings',
        name: 'SystemSettings',
        activeKey: '/SystemSettings',
        meta: {
          id: 'SystemSettings',
          title: '系统设置',
          icon: 'xitongshezhi'
        },
        component: () => import(/* webpackChunkName: "SystemSettings" */ '@/views/SystemSettings'),
        children: [
          {
            path: 'PersonnelManagement',
            name: 'PersonnelManagement',
            activeKey: '/SystemSettings',
            meta: {
              title: '人员管理'
            },
            component: () => import(/* webpackChunkName: "PersonnelManagement" */ '@/views/SystemSettings/PersonnelManagement')
          },
          {
            path: 'RoleManagement',
            name: 'RoleManagement',
            activeKey: '/SystemSettings',
            meta: {
              title: '角色管理'
            },
            component: () => import(/* webpackChunkName: "RoleManagement" */ '@/views/SystemSettings/RoleManagement')
          }
        ]
      },
      {
        path: '/LogisticsCenter',
        name: 'LogisticsCenter',
        activeKey: '/LogisticsCenter',
        meta: {
          id: 'LogisticsCenter',
          title: '物流中心',
          icon: 'xitongshezhi'
        },
        component: () => import(/* webpackChunkName: "LogisticsCenter" */ '@/views/LogisticsCenter'),
        children: [
          {
            path: 'LogisticsManagement',
            name: 'LogisticsManagement',
            activeKey: '/LogisticsCenter',
            meta: {
              title: '物流管理'
            },
            component: () => import(/* webpackChunkName: "LogisticsManagement" */ '@/views/LogisticsCenter/LogisticsManagement')
          },
          {
            path: 'FreightTemplate',
            name: 'FreightTemplate',
            activeKey: '/FreightTemplate',
            meta: {
              title: '运费模板'
            },
            component: () => import(/* webpackChunkName: "FreightTemplate" */ '@/views/LogisticsCenter/FreightTemplate')
          },
          {
            path: 'CreateFreightTemplate',
            name: 'CreateFreightTemplate',
            activeKey: '/CreateFreightTemplate',
            customPath: [
              { name: 'FreightTemplate', title: '运费模版', icon: 'shangpinzhongxin' },
              { name: 'CreateFreightTemplate', title: '新增运费模版', to: 'CreateFreightTemplate' }
            ],
            meta: {
              title: '新增运费模板',
              redirectPath: '/LogisticsCenter/FreightTemplate'
            },
            component: () =>
              import(/* webpackChunkName: "CreateFreightTemplate" */ '@/views/LogisticsCenter/FreightTemplate/modules/create.vue')
          },
          {
            path: 'GuidanceShipping',
            name: 'GuidanceShipping',
            activeKey: '/GuidanceShipping',
            meta: {
              title: '指导运费'
            },
            component: () =>
              import(
                /* webpackChunkName: "GuidanceShipping" */ '@/views/LogisticsCenter/FreightTemplate/modules/guidanceShipping'
              )
          }
        ]
      },
       // 修改密码
      {
        path: '/ChangePasswordView',
        name: 'ChangePasswordView',
        redirect: '/ChangePassword',
        component: () => import(/* webpackChunkName: "LoginView" */ '@/views/ChangePassword/ChangePasswordView.vue'),
        children: [
          {
            path: '/ChangePassword',
            name: 'ChangePassword',
            activeKey: '/ChangePassword',
            meta: {
              id: 'ChangePassword',
              title: '修改密码'
            },
            component: () => import(/* webpackChunkName: "loginIndex" */ '@/views/ChangePassword/modules/index.vue')
          },
          {
            path: '/ChangeAccount',
            name: 'ChangeAccount',
            activeKey: '/ChangeAccount',
            meta: {
              id: 'ChangeAccount',
              title: '修改手机号/邮箱'
            },
            component: () => import(/* webpackChunkName: "ChangeAccount" */ '@/views/ChangeAccount/modules/index.vue')
          },
        ]
      }
    ]
  },
  // 容器视图
  {
    path: '/loginView',
    name: 'LoginView',
    redirect: '/login',
    component: () => import(/* webpackChunkName: "LoginView" */ '@/views/Login/loginView.vue'),
    children: [
      // 登录
      {
        path: '/login',
        name: 'loginIndex',
        activeKey: '/login',
        meta: {
          id: 'loginIndex',
          title: '登录'
        },
        component: () => import(/* webpackChunkName: "loginIndex" */ '@/views/Login/modules/index.vue')
      },
      // 忘记密码
      {
        path: '/forgetPwd',
        name: 'forgetPwd',
        activeKey: '/login',
        meta: {
          id: 'forgetPwd',
          title: '忘记密码'
        },
        component: () => import(/* webpackChunkName: "forgetPwd" */ '@/views/Login/modules/forgetPwd.vue')
      },
      // 注册
      {
        path: '/register',
        name: 'register',
        activeKey: '/login',
        meta: {
          id: 'register',
          title: '注册'
        },
        component: () => import(/* webpackChunkName: "forgetPwd" */ '@/views/Login/modules/register')
      }
    ]
  },
 
]

//避免冗余导航到当前位置
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 使用全局导航守卫函数
router.beforeEach(globalGuard)

export default router
